import React, { Component } from 'react';
import moment from 'moment';
import {
  Select,
  Col,
  Card,
  Row,
  message as showMessage,
  Spin,
  Form,
  Icon,
  Button,
  Modal,
  Input,
  Tabs,
  DatePicker,
  Tag,
} from 'antd';
import { delayApiRequest } from '../../helper/utility';
import API from '../../helper/api';
import { getData, postData } from '../../helper/dataService';
import InvoiceDataTable from './InvoiceDataTable';
import ReceiptsDataTable from './ReceiptsDataTable';
import Pagination from '../Pagination';
import { PermissionContext } from '../../permissionContext';

class BillsView extends Component {
  static contextType = PermissionContext;

  state = {
    orgData: [],
    orgFetching: false,
    billingData: null,
    billingDataFetching: false,
    invoicesData: null,
    invoicesDataFetching: true,
    orgId: null,
    billingProfileId: null,
    showModal: false,
    submitted: false,
    receiptsData: null,
    receiptsDataFetching: true,
    showRemarksInput: false,
    showDateInput: false,
    remarksValue: '',
    dueDateValue: '',
  };

  fetchOrg = (value) => {
    this.setState({ orgData: [], orgFetching: true });
    getData(API.organization.search(value))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ orgData: data });
        }
        this.setState({ orgFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ orgFetching: false });
      });
  };

  handleFetchOrg = (organizationName) => {
    if (organizationName.length >= 4) {
      delayApiRequest(() => this.fetchOrg(organizationName));
    }
  };

  handleOrgChange = (value) => {
    this.setState({ billingData: null, billingDataFetching: true });
    getData(API.bills.details(value))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ billingData: data });
          this.setState({ billingProfileId: data.billing_profile_id });
          this.setState({ remarksValue: data.remarks || '' });
          this.setState({ dueDateValue: data.due_date.$date });
        }
        this.setState({ billingDataFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ billingDataFetching: false });
      });

    this.setState({ orgId: value });
  };

  saveInvoiceData = (data, isLoading) => {
    this.setState({ invoicesData: data, invoicesDataFetching: isLoading });
  };

  saveReceiptsData = (data, isLoading) => {
    this.setState({ receiptsData: data, receiptsDataFetching: isLoading });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  handleSubmit = (e) => {
    const { billingProfileId, orgId } = this.state;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitted: true });
        postData(API.bills.addPayment(billingProfileId), {
          amount: values.amount,
          payment_method: values.payment_method,
          paid_via: values.paid_via || '',
        })
          .then(({ data: { status, message } }) => {
            if (status === 'success') {
              showMessage.success(message);

              // Clear current FORM state
              this.props.form.setFieldsValue({
                amount: '',
                payment_method: '',
                paid_via: '',
              });

              this.handleOrgChange(orgId);
              this.setState({ showModal: false });
            } else if (status === 'failed') {
              showMessage.error(message);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  toggleRemarksInput = () => {
    this.setState((prevState) => ({
      showRemarksInput: !prevState.showRemarksInput,
    }));
  };

  toggleDateInput = () => {
    this.setState((prevState) => ({
      showDateInput: !prevState.showDateInput,
    }));
  };

  handleUpdate = (type) => {
    const { orgId, remarksValue, dueDateValue } = this.state;
    if (type === 'remarks') {
      postData(API.bills.details(orgId), { remarks: remarksValue })
        .then(({ data: { status, message } }) => {
          if (status === 'success') {
            showMessage.success(message);
            this.setState((prevState) => ({
              billingData: { ...prevState.billingData, remarks: remarksValue },
            }));
            this.setState({ showRemarksInput: false });
          } else if (status === 'error') {
            showMessage.error(message);
          }
        })
        .catch(() => {
          showMessage.error('Sorry! Something went wrong');
        });
    } else {
      postData(API.bills.details(orgId), {
        due_date: moment(dueDateValue).format('YYYY-MM-DD'),
      })
        .then(({ data: { status, message } }) => {
          if (status === 'success') {
            showMessage.success(message);
            this.setState((prevState) => ({
              billingData: {
                ...prevState.billingData,
                due_date: { $date: dueDateValue },
              },
            }));
            this.setState({ showDateInput: false });
          } else if (status === 'error') {
            showMessage.error(message);
          }
        })
        .catch(() => {
          showMessage.error('Sorry! Something went wrong');
        });
    }
  };

  handleDate = (date, dateString) => {
    this.setState({ dueDateValue: date });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { checkSubSectionPermission } = this.context;
    const {
      orgData,
      orgFetching,
      billingDataFetching,
      billingData,
      invoicesData,
      orgId,
      showModal,
      submitted,
      receiptsData,
      receiptsDataFetching,
      invoicesDataFetching,
      showRemarksInput,
      remarksValue,
      showDateInput,
      dueDateValue,
    } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    let payable = 0;
    if (billingData) {
      payable = Number(billingData.due) - Number(billingData.balance);
    }

    const paymentMethods = ['Cash', 'Bkash', 'Nagad', 'Upay', 'Bank'];
    const paidVia = ['Brac Bank', 'City Bank', 'Prime Bank'];

    const selectedMethod = getFieldValue('payment_method');

    const { TabPane } = Tabs;
    const callback = (key) => {};

    return (
      <>
        <Row type='flex'>
          <Col xs={24} sm={18} lg={24}>
            <Card
              title={
                <span className='title'>
                  <Icon type='fund' /> Billing
                </span>
              }
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Select
                  style={{ width: '400px' }}
                  showSearch
                  placeholder='Search and Select Organization'
                  filterOption={false}
                  notFoundContent={
                    orgFetching ? <Spin size='small' /> : 'No Content Found'
                  }
                  onSearch={this.handleFetchOrg}
                  onChange={this.handleOrgChange}
                >
                  {orgData.length > 0 &&
                    orgData.map((item) => (
                      <Select.Option key={item._id.$oid}>
                        {item.name} [{item.organization_id}]
                      </Select.Option>
                    ))}
                </Select>

                {orgId && checkSubSectionPermission('bills', 'add_payment') && (
                  <Button type='primary' onClick={this.handleModal}>
                    Add Balance
                  </Button>
                )}
              </div>

              {billingDataFetching ? (
                <Row
                  type='flex'
                  justify='center'
                  align='middle'
                  style={{ padding: 70 }}
                >
                  <Spin />
                </Row>
              ) : null}

              {billingData ? (
                <>
                  <Row
                    type='flex'
                    justify='center'
                    gutter={16}
                    style={{ marginTop: 20 }}
                  >
                    <Col span={12}>
                      <Card>
                        <Form layout='vertical'>
                          <Form.Item label='Billing ID' {...formItemLayout}>
                            {billingData.billing_profile_id}
                          </Form.Item>
                          <Form.Item label='Name' {...formItemLayout}>
                            {billingData.name}
                          </Form.Item>
                          <Form.Item label='Address' {...formItemLayout}>
                            {billingData.address}
                          </Form.Item>
                          <Form.Item label='Type' {...formItemLayout}>
                            {billingData.organization_type}
                          </Form.Item>
                          <Form.Item
                            label='Total Assets'
                            {...formItemLayout}
                            style={{ paddingBottom: 0 }}
                          >
                            {billingData.number_of_active_assets} /{' '}
                            {billingData.number_of_assets}
                          </Form.Item>
                          <Form.Item
                            label='Remarks'
                            {...formItemLayout}
                            style={{ paddingBottom: 0 }}
                          >
                            {showRemarksInput ? (
                              <div>
                                <Input.TextArea
                                  rows={4}
                                  placeholder='Remarks'
                                  value={remarksValue}
                                  onChange={(e) =>
                                    this.setState({
                                      remarksValue: e.target.value,
                                    })
                                  }
                                />
                                <div style={{ display: 'flex', gap: 10 }}>
                                  <Button
                                    type='primary'
                                    onClick={() => this.handleUpdate('remarks')}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        showRemarksInput: false,
                                      });
                                      this.setState({
                                        remarksValue: billingData.remarks,
                                      });
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div style={{ display: 'flex', gap: 20 }}>
                                {billingData.remarks || 'Add remarks'}
                                {checkSubSectionPermission('bills', 'edit') && (
                                  <Button
                                    icon='edit'
                                    size='small'
                                    onClick={this.toggleRemarksInput}
                                  />
                                )}
                              </div>
                            )}
                          </Form.Item>
                        </Form>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card size='small'>
                            <div
                              style={{
                                display: 'flex',
                                gap: 30,
                                justifyContent: 'center',
                              }}
                            >
                              <div style={{ fontWeight: 'bold', fontSize: 17 }}>
                                Balance:&nbsp;{billingData.balance}
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card size='small'>
                            {showDateInput}
                            <div
                              style={{
                                display: 'flex',
                                gap: 30,
                                justifyContent: 'center',
                              }}
                            >
                              <div style={{ fontWeight: 'bold', fontSize: 17 }}>
                                Due:&nbsp;{billingData.due}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card size='small'>
                            <div
                              style={{
                                display: 'flex',
                                gap: 30,
                                justifyContent: 'center',
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 17,
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                Payable:&nbsp;
                                {payable < 0 ? 0 : payable}
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card size='small'>
                            {showDateInput ? (
                              <div style={{ display: 'flex', gap: 10 }}>
                                <DatePicker
                                  placeholder='Select installation date'
                                  format='DD-MM-YYYY'
                                  showToday={false}
                                  value={moment(dueDateValue)}
                                  onChange={this.handleDate}
                                  disabledTime
                                  style={{ width: '100%' }}
                                  disabledDate={(current) =>
                                    current < moment().startOf('day') ||
                                    current >
                                      moment().add(6, 'months').endOf('day')
                                  }
                                />
                                <div style={{ display: 'flex', gap: 10 }}>
                                  <Button
                                    type='primary'
                                    onClick={() =>
                                      this.handleUpdate('due_date')
                                    }
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      this.setState({ showDateInput: false });
                                      this.setState({
                                        dueDateValue:
                                          billingData.due_date.$date,
                                      });
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 17,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: 10,
                                }}
                              >
                                Due Date:&nbsp;
                                {payable > 0
                                  ? moment(billingData.due_date.$date).format(
                                      'DD MMMM YYYY'
                                    )
                                  : 'Not Applicable'}
                                {payable > 0 &&
                                  moment().isAfter(
                                    moment(billingData.due_date.$date)
                                  ) && <Tag color='red'>Overdue</Tag>}
                                {payable > 0 &&
                                  checkSubSectionPermission(
                                    'bills',
                                    'edit'
                                  ) && (
                                    <Button
                                      icon='edit'
                                      size='small'
                                      onClick={this.toggleDateInput}
                                    />
                                  )}
                              </div>
                            )}
                          </Card>
                        </Col>
                      </Row>

                      <Card
                        title={
                          <span style={{ fontWeight: 500 }}>
                            <Icon type='user' /> Billing Person
                          </span>
                        }
                        style={{ marginTop: 18 }}
                      >
                        <Form layout='vertical'>
                          <Form.Item label='Name' {...formItemLayout}>
                            {billingData.billing_person.name}
                          </Form.Item>
                          <Form.Item label='Mobile' {...formItemLayout}>
                            {billingData.billing_person.mobile}
                          </Form.Item>
                          <Form.Item
                            label='Email'
                            {...formItemLayout}
                            style={{ marginBottom: 10 }}
                          >
                            {billingData.billing_person.email}
                          </Form.Item>
                        </Form>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Card>
          </Col>
        </Row>
        {billingData && (
          <Row
            type='flex'
            justify='space-around'
            align='middle'
            xs={24}
            sm={18}
            lg={24}
          >
            <Col xs={24} sm={18} lg={24} style={{ backgroundColor: 'white' }}>
              <Tabs defaultActiveKey='1' onChange={callback} animated={false}>
                <TabPane tab='INVOICES' key='1'>
                  <Card>
                    <Pagination
                      path={API.bills.invoices(orgId)}
                      saveData={this.saveInvoiceData}
                    />
                    {invoicesData ? (
                      <InvoiceDataTable
                        data={invoicesData}
                        loading={invoicesDataFetching}
                        orgId={orgId}
                      />
                    ) : null}
                  </Card>
                </TabPane>
                <TabPane tab='RECEIPTS' key='2'>
                  <Card>
                    <Pagination
                      path={API.bills.receipts(orgId)}
                      saveData={this.saveReceiptsData}
                    />
                    <ReceiptsDataTable
                      data={receiptsData}
                      loading={receiptsDataFetching}
                      orgId={orgId}
                    />
                  </Card>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        )}

        {showModal && (
          <Modal
            title='Add Balance'
            visible
            onCancel={this.handleModal}
            footer={null}
            maskClosable={false}
            width={600}
          >
            <Form onSubmit={this.handleSubmit} layout='vertical'>
              <Form.Item label='Amount' {...formItemLayout}>
                {getFieldDecorator('amount', {
                  rules: [
                    {
                      // eslint-disable-next-line no-useless-escape
                      pattern: /^\d+$/,
                      message: 'The input is not a valid amount!',
                    },
                    {
                      required: true,
                      message: 'Please input Amount!',
                    },
                  ],
                })(<Input placeholder='Enter Amount' />)}
              </Form.Item>
              <Form.Item label='Payment Method' {...formItemLayout}>
                {getFieldDecorator('payment_method', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a Payment Method',
                    },
                  ],
                })(
                  <Select placeholder='Select Payment Method'>
                    {paymentMethods.map((item) => (
                      <Select.Option key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {selectedMethod === 'Bank' && (
                <Form.Item label='Paid Via' {...formItemLayout}>
                  {getFieldDecorator('paid_via', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select Paid Via',
                      },
                    ],
                  })(
                    <Select placeholder='Select Paid Via'>
                      {paidVia.map((item) => (
                        <Select.Option key={item}>{item}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  loading={submitted}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(BillsView);
