import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Icon,
  Button,
  Modal,
  Form,
  Popconfirm,
  message as showMessage,
} from 'antd';
import moment from 'moment';
import { deleteData } from '../../helper/dataService';
import API from '../../helper/api';
import { PermissionContext } from '../../permissionContext';

class ReceiptsDataTable extends React.Component {
  static contextType = PermissionContext;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalData: null,
      receiptsData: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ receiptsData: this.props.data });
    }
  }

  handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  handleDelete = (receiptNumber, orgId, id) => {
    deleteData(API.bills.receipts(orgId), {
      receipt_id: id,
    })
      .then(({ data: { message, status } }) => {
        if (status === 'success') {
          showMessage.success(message);
          this.setState((prevState) => ({
            receiptsData: prevState.receiptsData.filter(
              (receipt) => receipt.receipt_number !== receiptNumber
            ),
          }));
          this.handleModal();
        }
        this.setState({
          data: { ...this.state.data, mobile: '' },
          submitted: false,
        });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ submitted: false });
      });
  };

  render() {
    const { loading, orgId } = this.props;
    const { showModal, modalData, receiptsData } = this.state;
    const { checkSubSectionPermission } = this.context;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='snippets' />
              &nbsp;
              {'List of Receipts'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={loading}
          dataSource={receiptsData}
          rowKey={({ _id }) => _id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='Receipt Number' dataIndex='receipt_number' />
          <Table.Column title='Description' dataIndex='description' />
          <Table.Column title='Amount' dataIndex='amount' />
          <Table.Column
            title='Status'
            dataIndex='status'
            render={(status) => (
              <span style={{ textTransform: 'capitalize' }}>{status}</span>
            )}
          />
          <Table.Column
            title='Paid On'
            dataIndex='paid_on'
            render={(paid_on) => (
              <span>
                {paid_on
                  ? moment(paid_on.$date).format('DD-MMM-YYYY hh:mm a')
                  : ''}
              </span>
            )}
          />
          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={(_, record) => {
              return (
                <Button
                  type='primary'
                  ghost
                  onClick={() => {
                    this.handleModal();
                    this.setState({ modalData: record });
                  }}
                >
                  View
                </Button>
              );
            }}
          />
        </Table>

        {showModal && (
          <Modal
            title='Receipt Details'
            visible
            onCancel={this.handleModal}
            footer={null}
            maskClosable={false}
          >
            {modalData ? (
              <Form layout='vertical'>
                <Form.Item label='Receipt Number' {...formItemLayout}>
                  {modalData.receipt_number}
                </Form.Item>
                <Form.Item label='Description' {...formItemLayout}>
                  {modalData.description}
                </Form.Item>
                <Form.Item label='Amount' {...formItemLayout}>
                  {modalData.amount}
                </Form.Item>
                <Form.Item label='Payment Method' {...formItemLayout}>
                  {modalData.payment_method}
                </Form.Item>
                <Form.Item label='Paid via' {...formItemLayout}>
                  {modalData.paid_via || '-'}
                </Form.Item>
                <Form.Item label='Status' {...formItemLayout}>
                  <span style={{ textTransform: 'capitalize' }}>
                    {modalData.status}
                  </span>
                </Form.Item>
                <Form.Item label='Paid On' {...formItemLayout}>
                  {moment(modalData.paid_on.$date).format(
                    'DD-MMM-YYYY hh:mm a'
                  )}
                </Form.Item>
                {checkSubSectionPermission('bills', 'edit') &&
                  modalData.status === 'unsettled' && (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Popconfirm
                        title='Are you sure?'
                        onConfirm={() => {
                          this.handleDelete(
                            modalData.receipt_number,
                            orgId,
                            modalData._id.$oid
                          );
                        }}
                        onCancel={() => {}}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button type='danger'>Delete</Button>
                      </Popconfirm>
                    </div>
                  )}
              </Form>
            ) : null}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

ReceiptsDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ReceiptsDataTable;
