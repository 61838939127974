import axios from 'axios';
import API from './api';
import { getItem } from './utility';

const apiBase = process.env.REACT_APP_ADMIN_BASE_API;

export const getData = (path = '') => {
  return axios({
    method: 'get',
    url: `${apiBase}/${path}`,
    headers: {
      Authorization: `Bearer ${getItem('accessToken')}`,
    },
  });
};

export const postData = (path = '', data = {}) => {
  return axios({
    method: 'post',
    url: `${apiBase}/${path}`,
    data: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getItem('accessToken')}`,
    },
  });
};

export const deleteData = (path = '', data = {}) => {
  return axios({
    method: 'delete',
    url: `${apiBase}/${path}`,
    data: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getItem('accessToken')}`,
    },
  });
};

export const patchData = (path = '', data = {}) => {
  return axios({
    method: 'patch',
    url: `${apiBase}/${path}`,
    data: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getItem('accessToken')}`,
    },
  });
};

export const uploadFile = (path = '', file) => {
  return axios({
    method: 'post',
    url: `${apiBase}/${path}`,
    data: file,
    headers: {
      Authorization: `Bearer ${getItem('accessToken')}`,
    },
  });
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.data.message === 'expired token') {
        return postData(API.auth.refreshToken, {
          access_token: getItem('accessToken'),
          refresh_token: getItem('refreshToken'),
        }).then(() => axios(originalRequest));
      }
      if (
        response.data.message === 'invalid token' ||
        response.data.message === 'unauthorized token'
      ) {
        // remove saved user info from local storage
        window.localStorage.clear();
        // redirect to login page
        window.location.reload();
      } else if (response.status === 500) {
        console.log('Server Error', response);
      }
    }
    return Promise.reject(error);
  }
);
