import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Icon,
  Button,
  Modal,
  Form,
  Input,
  message as showMessage,
} from 'antd';
import moment from 'moment';
import API from '../../helper/api';
import { postData } from '../../helper/dataService';
import { PermissionContext } from '../../permissionContext';

class InvoiceDataTable extends React.Component {
  static contextType = PermissionContext;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showInput: false,
      modalData: null,
      additionalDiscount: 0,
      totalAmount: 0,
      invoiceData: props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ invoiceData: this.props.data });
    }
  }

  handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  toggleShowInput = () => {
    this.setState((prevState) => ({
      showInput: !prevState.showInput,
    }));
  };

  handleUpdate = (orgId, data) => {
    const { additionalDiscount } = this.state;
    postData(API.bills.invoices(orgId), {
      discount_amount: Number(additionalDiscount),
      invoice_id: data._id.$oid,
    })
      .then(({ data: { status, message } }) => {
        if (status === 'success') {
          showMessage.success(message);
          this.setState((prevState) => ({
            invoiceData: prevState.invoiceData.map((invoice) =>
              invoice.invoice_number === data.invoice_number
                ? {
                    ...invoice,
                    additional_discount_amount: additionalDiscount,
                    total_amount:
                      invoice.billed_amount -
                      invoice.applied_discount_amount -
                      Number(additionalDiscount),
                  }
                : invoice
            ),
          }));
          this.setState({ showInput: false });
          this.setState({
            totalAmount:
              data.billed_amount -
              data.applied_discount_amount -
              additionalDiscount,
          });
        } else if (status === 'error') {
          showMessage.error(message);
        }
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
      });
  };

  render() {
    const { loading, orgId } = this.props;
    const { checkSubSectionPermission } = this.context;
    const {
      showModal,
      modalData,
      showInput,
      additionalDiscount,
      invoiceData,
      totalAmount,
    } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='snippets' />
              &nbsp;
              {'List of Invoice'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={loading}
          dataSource={invoiceData}
          rowKey={({ _id }) => _id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='Invoice Number' dataIndex='invoice_number' />
          <Table.Column title='Description' dataIndex='description' />
          <Table.C olumn title='Total Amount' dataIndex='total_amount' />
          <Table.Column
            title='Status'
            dataIndex='status'
            render={(status) => (
              <span style={{ textTransform: 'capitalize' }}>{status}</span>
            )}
          />
          <Table.Column
            title='Created On'
            dataIndex='created_on'
            render={(created_on) => (
              <span>
                {created_on
                  ? moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')
                  : ''}
              </span>
            )}
          />
          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={(_, record) => {
              return (
                <Button
                  type='primary'
                  ghost
                  onClick={() => {
                    this.handleModal();
                    this.setState({ modalData: record });
                    this.setState({
                      additionalDiscount: record.additional_discount_amount,
                      totalAmount: record.total_amount,
                    });
                  }}
                >
                  View
                </Button>
              );
            }}
          />
        </Table>

        {showModal && (
          <Modal
            title='Invoice Details'
            visible
            onCancel={this.handleModal}
            footer={null}
            maskClosable={false}
          >
            {modalData ? (
              <Form layout='vertical'>
                <Form.Item label='Invoice Number' {...formItemLayout}>
                  {modalData.invoice_number}
                </Form.Item>
                <Form.Item label='Description' {...formItemLayout}>
                  {modalData.description}
                </Form.Item>
                <Form.Item label='Billed Amount' {...formItemLayout}>
                  {modalData.billed_amount}
                </Form.Item>
                <Form.Item label='Applied Discount' {...formItemLayout}>
                  {modalData.applied_discount_amount}
                </Form.Item>
                <Form.Item label='Additional Discount' {...formItemLayout}>
                  {showInput ? (
                    <div style={{ display: 'flex', gap: 20 }}>
                      <Input
                        value={additionalDiscount}
                        style={{ width: 120 }}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            this.setState({ additionalDiscount: value });
                          }
                        }}
                      />
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Button
                          type='primary'
                          onClick={() => this.handleUpdate(orgId, modalData)}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            this.toggleShowInput();
                            this.setState({
                              additionalDiscount:
                                modalData.additional_discount_amount,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', gap: 10 }}>
                      {additionalDiscount}
                      {checkSubSectionPermission('bills', 'edit') &&
                        modalData.status === 'unpaid' && (
                          <Button
                            icon='edit'
                            size='small'
                            onClick={this.toggleShowInput}
                          />
                        )}
                    </div>
                  )}
                </Form.Item>
                <Form.Item label='Total Amount' {...formItemLayout}>
                  {totalAmount}
                </Form.Item>
                <Form.Item label='Status' {...formItemLayout}>
                  <span style={{ textTransform: 'capitalize' }}>
                    {modalData.status}
                  </span>
                </Form.Item>
                <Form.Item label='Created On' {...formItemLayout}>
                  {moment(modalData.created_on.$date).format(
                    'DD-MMM-YYYY hh:mm a'
                  )}
                </Form.Item>
              </Form>
            ) : null}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

InvoiceDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InvoiceDataTable;
